label {
  margin-bottom: 10px;
  display: block;
}

select, input[type="number"] {
  padding: 5px;
  font-size: 16px;
}

button {
  padding: 10px;
  font-size: 18px;
}

#results {
  margin-top: 20px;
}

/*# sourceMappingURL=index.910a847f.css.map */
